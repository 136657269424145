import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './Map.module.css'
import config from '../config'

mapboxgl.accessToken = config.MAPBOX_API_KEY

function Map({incidents}) {
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);

  useEffect(() => {
    const TORONTO_BOUNDS = new mapboxgl.LngLatBounds(
      [-79.54253236483933, 43.584465212209516],
      [-79.17035432333257, 43.8555429330093]
    );

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      bounds: TORONTO_BOUNDS,
      zoom: 11.5
    })

    setMap(map)
  }, []);

  useEffect(() => {
    if (!map) return

    const addMarkersToMap = (incidents) => {
      incidents.forEach(i => {
        const el = document.createElement('div');

        el.className = styles.marker;

        const geojson = i.geojson;

        new mapboxgl.Marker(el)
          .setLngLat(geojson.geometry.coordinates)
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }).setHTML(
              `
                  <table>
                    <tr>
                      <th>Type</th>
                      <td>${geojson.properties.eventType}</td>
                    </tr>
                    <tr>
                      <th>Alarm Level</th>
                      <td>${geojson.properties.alarmLevel}</td>
                    </tr>
                    <tr>
                      <th>Location</th>
                      <td>
                        <div>${geojson.properties.primeStreet ? geojson.properties.primeStreet : ''}</div>
                        <div>${geojson.properties.crossStreets ? geojson.properties.crossStreets : ''}</div>
                      </td>
                    </tr>
                    <tr>
                      <th>Dispatched Units</th>
                      <td>${geojson.properties.dispatchedUnits}</td>
                    </tr>
                  </table>
              `
            )
          )
          .addTo(map);
      })
    }

    addMarkersToMap(incidents)
  }, [map, incidents]);

  return (
    <div>
      <div ref={mapContainer} className={styles.container} />
    </div>
  )
}

export default Map;