import Map from './Map'
import getIncidents from '../api';

import { useState, useEffect } from 'react'

function App() {
  const [incidents, setIncidents] = useState([]);

  const addGeoJSONDetails = (incident) => {
    return {
      ...incident,
      geojson: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [incident['longitude'], incident['latitude']]
        },
        properties: {
          title: 'title',
          description: 'description',
          eventType: incident['event_type'],
          alarmLevel: incident['alarm_level'],
          primeStreet: incident['prime_street'],
          crossStreets: incident['cross_streets'],
          dispatchedUnits: incident['dispatched_units']
        }
      }
    }
  }
  useEffect(() => {
    getIncidents()
      .then(incidents => incidents.map(addGeoJSONDetails))
      .then(setIncidents)
  }, [])

  return <Map incidents={incidents}/>
}

export default App;